import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: black;
  color: white;
  text-align: center;
  padding: 10px;
  bottom: 0;
  width: 100%;
  position: relative;
`;

const Footer = () => {
    return (
        <FooterContainer>
            <p>&copy; 2024 TorTime.co.il. All rights reserved.</p>
        </FooterContainer>
    );
};

export default Footer;
