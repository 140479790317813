import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import image1 from '../assets/image1.jpeg';
import image2 from '../assets/image2.jpeg';
import image3 from '../assets/image3.jpeg';
import planbackground from '../assets/planbackground.jpg';
import video1 from '../assets/video1.mp4'; // הוספת קובץ הווידאו

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;



const MainVideo = styled.video`
  width: 300px;
  height: auto;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  background-color: #000;

  @media (max-width: 768px) {
    width: 80%; /* Reduce the video size for mobile */
    margin-bottom: 20px;
  }
`;
const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  margin-top: 40px;
  padding: 0 10px;
  gap: 20px;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    max-width: 75%;
  }
`;

// עדכון ה-Box כך שיושפע מגודל הטקסט מהפרופס
const Box = styled.div`
  flex: 1;
  min-width: 250px;
  background: linear-gradient(135deg, #f19b9b, #ffffff);
  color: #000000;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-size: ${(props) => props.fontSize}px; /* הגדרת גודל טקסט דינמית */

  &:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  }

  h3 {
    color: #000000;
    font-size: ${(props) => props.fontSize * 1.5}px; /* הגדלת הכותרות */
    margin-bottom: 15px;
  }

  p {
    color: #333;
    font-size: ${(props) => props.fontSize}px; /* הגדרת גודל הטקסט עבור פסקאות */
    line-height: 1.6;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const WhyUsSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  padding: 40px 20px;
  width: 100%; /* Make it full width */
  max-width: 1500px; /* Optional: to control the maximum width */
  margin: 60px auto; /* Center it horizontally */
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  direction: rtl;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    max-width: 90%; /* Center the text on smaller screens */
  }
`;


const WhyUsText = styled.div`
  flex: 1;
  padding-right: 20px;

  ul {
    list-style-type: none;
    padding: 0;
    font-size: 18px;
    color: #000000;

    li {
      margin-bottom: 10px;
      position: relative;
      padding-left: 20px;

      &::before {
        content: "★";
        position: relative;
        left: 10px;
        color: #e63946;
      }
    }
  }

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px;
  }
`;

const WhyUsImage = styled.img`
  flex: 1;
  width: 100%; /* Make the image full width of the container */
  max-width: 300px; /* Control the maximum width of the image */
  height: auto;
  transform: rotate(-10deg);
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    width: 80%;
    transform: rotate(0); /* No rotation on mobile */
  }
`;

const HeroSection = styled.section`
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #000, #e63946);
  text-align: center;
  padding: 0 10px;
  background-size: cover;
  background-position: center;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  direction: rtl;

  @media (max-width: 768px) {
    padding-top: 20px;
    height: auto;
    
  }
`;




const HeroTitle = styled.h1`
  color: #fff;
  font-size: 36px; /* Smaller title */
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  animation: ${fadeIn} 1s ease-out;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 24px; /* Smaller for mobile */
  }
`;

const HeroSubtitle = styled.p`
  color: #fff;
  font-size: 20px; /* Smaller subtitle */
  margin-bottom: 30px;
  animation: ${fadeIn} 1.2s ease-out;

  @media (max-width: 768px) {
    font-size: 16px; /* Smaller for mobile */
  }
`;

const CTAButton = styled.a`
  background-color: #e63946;
  color: white;
  padding: 10px 25px;
  font-size: 16px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold; /* הוספת כתב בולט */
  transition: background-color 0.3s ease;
  animation: ${fadeIn} 1.4s ease-out;

  &:hover {
    background-color: #1d3557;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 20px; /* Smaller button for mobile */
  }
`;

// Image styles
const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden; /* Ensure no horizontal overflow */
  
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 20px;
    padding: 0 10px;
  }
`;


const SideImage = styled.img`
  width: 200px;
  height: auto;
  border-radius: 20px;
  position: relative;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  transform: rotate(-10deg);

  &.right {
    transform: rotate(10deg);
  }

  @media (max-width: 768px) {
    width: 50%;  /* Reduce size for mobile */
    margin-top: 20px;
    transform: none;
  }
`;

const PopUpContainer = styled.div`
direction: rtl;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(135deg, #000, #e63946);
  color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999; // להבטיח שהוא מופיע מעל כל האלמנטים האחרים
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  transition: opacity 0.3s ease;

  @media (max-width: 768px) {
    width: 90%;
    max-width: 80%;
  }
`;



const CloseButton = styled.button`
  background-color: transparent;
  color: #e63946;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  &:hover {
    color: #d62828;
  }
`;

const InputField = styled.input`
  padding: 10px;
  margin: 10px 0;
  width: 90%;
  border: none;
  color: #000;
  border-radius: 5px;
  font-size: 16px;
  direction: rtl;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const SendButton = styled.button`
  background-color: #e63946;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #d62828;
  }

  @media (max-width: 768px) {
    width: 95%;
  }
`;
const PlansSection = styled.section`
  padding: 60px 0;
  text-align: center;
  background-color: transparent;
  max-width: 100%;
  padding: 0 10px;

  @media (max-width: 768px) {
    padding: 40px 0;
  }
`;

const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`;

// Plan Card
const PlanCard = styled.div`
  width: 300px;
  height: 400px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: 90%; /* Reduce size for mobile */
    height: auto;
  }
`;
// Plan Content
const PlanContent = styled.div`
  padding: 20px;
  text-align: right;
  width: 100%;
  color: white;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 14px;
  }
`;

// Plan Price
const PlanPrice = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  text-align: right; /* טקסט מיושר לימין */
  color: #fff;
`;
const RedHeading = styled.h4`
  color: #ffffff; /* אדום */
`;

const Home = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasScrolled, setHasScrolled] = useState(false);


  const handleShowPopUp = () => {
    setShowPopUp(true); // מציג את הפופאפ בלחיצה על כפתור צור קשר
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 3700 && !hasScrolled) {
        setShowPopUp(true);
        setHasScrolled(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasScrolled]);
  const handleSend = () => {
    if (name === '' || phone === '') {
      setErrorMessage('יש למלא את כל השדות');
      return;
    }

    // Check if the phone number has exactly 10 digits
    if (!/^\d{10}$/.test(phone)) {
      setErrorMessage('מספר הטלפון חייב להיות בדיוק 10 תווים');
      return;
    }
    const message = `היי, קוראים לי ${name} אני יוצר איתך קשר עכשיו דרך האתר. מספר הטלפון שלי הוא ${phone} אשמח שנדבר לגביי האפליקציה`;
    const whatsappLink = `https://wa.me/0543038671?text=${encodeURIComponent(message)}`;

    window.open(whatsappLink, '_blank');
  };

  return (
    <>
      {/* Hero Section */}
      <HeroSection id="home">
        <HeroTitle>ברוכים הבאים לעתיד של ניהול העסק שלכם</HeroTitle>
        <HeroSubtitle>
          הגיע הזמן להתקדם למערכת מתקדמת שתשדרג את חוויית הלקוחות ותייעל את התפעול היומיומי שלכם. בעזרת המערכת שלנו לניהול תורים חכם, תוכלו לנהל הזמנות, עובדים, ושירותים בצורה פשוטה ומהירה.
        </HeroSubtitle>
        <CTAButton onClick={handleShowPopUp}>צרו איתנו קשר והתחילו עוד היום</CTAButton>


        {/* Image Wrapper */}
        <ImageWrapper>
          
          <SideImage className="left" src={image1} alt="Image Left" />
          <MainVideo src={video1} autoPlay muted loop playsInline alt="Main Video" /> {/* וידאו אוטומטי */}
          <SideImage className="right" src={image2} alt="Image Right" />
        </ImageWrapper>

        {/* Box Section */}
        <BoxContainer id="gives">
          <Box>
            <h3> פיתוח אפליקציות ייחודיות בהתאמה אישית</h3>
            <p>
              אנו מציעים פיתוח אפליקציות בהתאמה אישית לכל בעל עסק, בין אם מדובר בבונת ציפורניים, מכון יופי, או מספרה.
              כל אפליקציה נבנית על פי הצרכים וההעדפות של הלקוח, עם הלוגו והעיצוב הייחודי לו.
              אנו עובדים צמוד עם כל בעל עסק, מבינים את החזון שלו, ומתרגמים אותו לאפליקציה פונקציונלית ומעוצבת בקפידה.
            </p>
          </Box>

          <Box>
            <h3> מערכת ניהול תורים מתקדמת</h3>
            <p>
              במרכז האפליקציה שלנו עומדת מערכת תורים חכמה, המאפשרת לבעלי עסקים לנהל את הזמן והמשאבים שלהם בצורה יעילה וחסכונית.
              המערכת מספקת כלים לניהול תורים, מעקב אחר לקוחות, ועדכוני תורים, והכול בממשק ידידותי ונוח.
            </p>
          </Box>

          <Box>
            <h3> ליווי אישי ותמיכה מלאה</h3>
            <p>
              אנו מבינים שכל עסק הוא ייחודי, ולכן מלווים את הלקוחות שלנו לכל אורך הדרך.
              אנו נפגשים עם כל בעל עסק, מבינים את הצרכים והעדפות שלו, ומסייעים לו בבחירת עיצוב וצבעים לאפליקציה.
            </p>
          </Box>

          <Box>
            <h3> מערכת ניהול כוללת</h3>
            <p>
              בנוסף, אנו מציעים מערכת ניהול כוללת בתוך האפליקציה, המאפשרת לבעלי עסקים לנהל תורים, עובדים, לשלוח הודעות ללקוחות,
              לנהל רשימות המתנה ולשלוח התראות. כל זה בתוך מערכת מתקדמת ונוחה, המותאמת במיוחד לעסק שלך.
            </p>
          </Box>
          <Box>
            <h3> שליחת הודעות ללא הגבלה</h3>
            <p>
              אנו מאפשרים גם שליחת הודעות לכל הלקוחות בלחיצת כפתור, תוך שמירה על מאגר הלקוחות שלך. תוכל לשלוח הודעות ועדכונים לכלל הלקוחות שלך בצורה מהירה ונוחה,
              ובכך להבטיח שתמיד תישאר מחובר עם הלקוחות שלך.
            </p>
          </Box>
        </BoxContainer>
        <WhyUsSection id="why-us">
          <WhyUsText>
            <h3>למה לבחור בנו?</h3>
            <ul>
              <li>אנחנו מציעים חודש ראשון חינם – תנו לעסק שלכם את ההזדמנות להתנסות ללא התחייבות.</li>
              <li>שירות מותאם אישית – אנחנו מבינים שכל עסק הוא ייחודי, ולכן אנו מתאימים את האפליקציה בדיוק לצרכים שלכם.</li>
              <li>איתנו אין טעויות – המערכת שלנו מדויקת, מהירה, ונוחה לתפעול, כדי שתוכלו לנהל את העסק שלכם בקלות ובשקט נפשי.</li>
              <li>אנחנו כאן בשבילכם – הצוות שלנו תמיד קשוב ופתוח לשינויים. כל בקשה לשדרוג או שינוי באפליקציה נעשה באהבה ובשמחה.</li>
              <li>טכנולוגיה מתקדמת – אנחנו משתמשים בטכנולוגיה העדכנית ביותר כדי להבטיח אפליקציה מהירה, מאובטחת ומקצועית.</li>
              <li>ממשק נוח וידידותי – הלקוחות שלכם ייהנו מתהליך הזמנת תורים פשוט ומהיר, ואתם תחסכו זמן יקר.</li>
            </ul>
          </WhyUsText>
          <WhyUsImage src={image3} alt="Why Choose Us" />
        </WhyUsSection>


        {/* Plans Section */}
        <PlansSection id="plans">
          <PlansContainer>

            {/* Plan 1 */}
            <PlanCard style={{ backgroundImage: `url(${planbackground})` }}>
              <PlanContent>
                <h3 style={{color:'white'}}>חבילת בסיס</h3>
                <p>החבילה המושלמת לעסק בתחילת דרכו, עם כלים לניהול תורים ואפשרויות בסיסיות ללקוחות.</p>
                <ul>
                  <li>ניהול תורים בסיסי</li>
                  <li>ללא עמלות על הזמנות</li>
                  <li>דוחות חודשיים</li>
                  <li>חודש ראשון חינם</li>
                </ul>
                <PlanPrice>₪99 לחודש</PlanPrice>
                <CTAButton onClick={handleShowPopUp}>התחל עכשיו</CTAButton>
              </PlanContent>
            </PlanCard>

            {/* Plan 2 */}
            <PlanCard style={{ backgroundImage: `url(${planbackground})` }}>
              <PlanContent>
                <h3 style={{ color: 'white' }}>חבילת פרימיום</h3>
                <p>לעסקים גדולים יותר שדורשים ניהול מתקדם, שירותים נוספים וממשק מותאם אישית.</p>
                <ul>
                  <li>ניהול תורים מתקדם</li>
                  <li>התראות והודעות אוטומטיות ללקוחות</li>
                  <li>ללא עמלות על הזמנות</li>
                  <li>דוחות והמלצות לשיפור</li>
                </ul>
                <PlanPrice>₪199 לחודש</PlanPrice>
                <CTAButton onClick={handleShowPopUp}>התחל עכשיו</CTAButton>
              </PlanContent>
            </PlanCard>

          </PlansContainer>
        </PlansSection>

      </HeroSection>

      {/* Pop-up */}
      {showPopUp && (
        <PopUpContainer visible={showPopUp}>
          <CloseButton onClick={() => setShowPopUp(false)}>✕</CloseButton>
          <RedHeading>צרו קשר עכשיו!</RedHeading>
          <p>הכניסו את שמכם ומספר הטלפון שלכם ונחזור אליכם בהקדם.</p>
          <InputField
            type="text"
            placeholder="הכניסו את שמכם"
            value={name}
            onChange={(e) => setName(e.target.value)} // Update name state
          />
          <InputField
            type="tel"
            placeholder="הכניסו את מספר הטלפון שלכם"
            value={phone}
            onChange={(e) => setPhone(e.target.value)} // Update phone state
          />
          {/* Display error message if validation fails */}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

          <SendButton onClick={handleSend}>שלח</SendButton> {/* Handle send on click */}

        </PopUpContainer>
      )}
    </>
  );
};

export default Home;
