import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styling for the Privacy Policy page container
const PrivacyPolicyContainer = styled.section`
  padding: 60px 20px;
  text-align: right;
  background-color: #f7f7f7;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease-out;
  direction: rtl;

  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  color: #e63946;
  margin-bottom: 20px;
  text-align: center;
  animation: ${fadeIn} 1s ease-out;
  
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  color: #1d3557;
  margin-bottom: 15px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const PrivacyPolicy = () => {
    return (
        <PrivacyPolicyContainer>
            <Title>מדיניות פרטיות עבור TorTime.co.il</Title>
            <Text>עודכן לאחרונה: 15.09.2024</Text>

            <SectionTitle>1. התחייבותנו לפרטיותך</SectionTitle>
            <Text>
                אנו ב-TorTime.co.il שמים דגש על הגנה על פרטיות המידע שלך. אנו פועלים בשקיפות בכל הנוגע לאיסוף, שימוש ושמירה של מידע אישי.
            </Text>

            <SectionTitle>2. סוגי המידע שאנו אוספים</SectionTitle>
            <Text>
                במהלך השימוש באפליקציה, אנו אוספים פרטים כמו שם מלא, מספר טלפון ופרטים נוספים הדרושים לתיאום תורים. בנוסף, אנו אוספים מידע על השימוש שלך באופן אוטומטי לשיפור השירות.
            </Text>

            <SectionTitle>3. השימוש במידע שלך</SectionTitle>
            <Text>
                המידע שנאסף ישמש לצורך מתן השירותים, התאמה אישית של חוויית השימוש, ביצוע תשלומים, ועמידה בדרישות החוק.
            </Text>

            <SectionTitle>4. שיתוף המידע</SectionTitle>
            <Text>
                אנו לא נשתף את המידע שלך עם צדדים שלישיים, אלא אם נדרש לכך על פי חוק או דרישת רשויות מוסמכות.
            </Text>

            <SectionTitle>5. אבטחת מידע</SectionTitle>
            <Text>
                אנו נוקטים באמצעי אבטחה מתקדמים לשמירה על המידע שלך. בעל העסק מחויב להגן על המידע בהתאם לחוקי הגנת הפרטיות.
            </Text>

            <SectionTitle>6. זכויותיך בנוגע למידע שלך</SectionTitle>
            <Text>
                באפשרותך לפנות אלינו כדי לעיין במידע שלך, לתקן אותו או לבקש את מחיקתו. לפניות יש לפנות לכתובת Baraktw@gmail.com.
            </Text>

            <SectionTitle>7. עדכוני מדיניות</SectionTitle>
            <Text>
                ייתכן שנעדכן את מדיניות הפרטיות מעת לעת. עדכונים מהותיים יופיעו באפליקציה או יישלחו אליך באמצעים המתאימים.
            </Text>

            <SectionTitle>8. יצירת קשר</SectionTitle>
            <Text>
                לשאלות או הבהרות בנוגע למדיניות זו, ניתן לפנות אלינו בכתובת Baraktw@gmail.com.
            </Text>
        </PrivacyPolicyContainer>
    );
};

export default PrivacyPolicy;
