import React from 'react';
import { Link as ScrollLink } from 'react-scroll'; // שימוש ב-react-scroll
import styled from 'styled-components';

const Nav = styled.nav`
  background: black;
  padding: 10px;
  direction: rtl;
  position: sticky;
  top: 0; /* שומר את ה-NAV תמיד למעלה */
  z-index: 1000; /* גורם לכך שה-NAVBAR יהיה מעל תוכן הדף */
  @media (max-width: 768px) {
    display: none; /* הסתרת ה-NAVBAR במכשירים ניידים */
  }
`;
const NavLinks = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledLink = styled(ScrollLink)` // שימוש ב-react-scroll
  color: white;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #e63946;
  }
`;

const Header = () => {
  return (
    <Nav>
      <NavLinks>
        <StyledLink to="home" smooth={true} duration={500}>דף הבית </StyledLink>
        <StyledLink to="gives" smooth={true} duration={500}>מה אתם מקבלים </StyledLink> {/* גלילה לקטע עם id="gives" */}
        <StyledLink to="why-us" smooth={true} duration={500}>למה לבחור בנו? </StyledLink>
        <StyledLink to="plans" smooth={true} duration={500}>חבילות ומחירים</StyledLink>
      </NavLinks>
    </Nav>
  );
};

export default Header;
